<template>
  <!-- 剧集抽屉 -->
  <a-drawer
    title="新增剧集"
    width="700"
    :closable="false"
    :visible="visible"
    @close="onClose"
    :footer-style="{ textAlign: 'right' }"
  >
    <a-form
      ref="RefForm"
      :model="formState"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 16 }"
    >
      <!-- 短剧 -->
      <a-form-item
        label="短剧"
        name="drama_id"
        :rules="[{ required: true, message: '请选择' }]"
      >
        <a-select
          show-search
          v-model:value="formState.drama_id"
          placeholder="请选择"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          :filterOption="filterOption"
          @change="onChangeDrama"
        >
          <a-select-option
            v-for="item in dramas"
            :key="`${item.id}-${item.remark_name}`"
            :value="item.id"
          >
            {{ item.remark_name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- 选择剧集 -->
      <a-form-item
        label="选择剧集"
        name="eqs"
        :rules="[{ required: true, message: '请选择' }]"
      >
        <div class="eq-list">
          <a-button
            class="eq-item"
            v-for="item in dramaEqs"
            :key="item.eq_number"
            :type="formState.eqs.includes(item.eq_number) ? 'primary' : 'default'"
            :disabled="!!item.status"
            @click="touchAdd(item.eq_number)"
          >
            {{ item.eq_number }}
          </a-button>
        </div>
      </a-form-item>
    </a-form>
    <!-- 尾部 -->
    <template #footer>
      <div class="footer-view">
        <span>共选择了{{ formState.eqs.length }}集</span>
        <div style="flex: 1;"></div>
        <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
        <a-button type="primary" @click="touchSubmit">确定</a-button>
      </div>
    </template>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
  </a-drawer>
</template>

<script setup>
import { ref, reactive, defineExpose, nextTick } from 'vue'
import { message } from 'ant-design-vue'
import Loading from '@/components/Loading'
import { dramaAll, recommendVideoEqList, recommendAdd } from '@/api/copyright'

// emit
const emit = defineEmits(['success'])
// Form实例
const RefForm = ref(null)
// 抽屉展示状态
let visible = ref(false)
// 加载
let isLoading = ref(false)
// 短剧
let dramas = ref([])
// 剧集列表
let dramaEqs = ref([])
// 表单
let formState = reactive({
  // 短剧
  drama_id: undefined,
  // 集数
  eqs: []
})


// 打开抽屉
function showDrawer () {
  // 展开
  visible.value = true
  // 赋值
  nextTick(() => {
    // 获取选择项列表
    getSelectList()
  })
}

// 获取选择项列表
function getSelectList () {
  dramaAll().then(res => {
    const { code, data, msg } = res
    if (code === 0) {
      dramas.value = data
    }
  })
}

// 选择短剧
function onChangeDrama (e) {
  // 对 formState.eqs 数组清空
  formState.eqs = []
  recommendVideoEqList({ drama_id: e }).then(res => {
    const { code, data, msg } = res
    if (code === 0) {
      dramaEqs.value = data
    }
  })
}

// 模糊匹配
function filterOption (input, option) {
  return `${option.key}`.toLowerCase().includes(`${input || ''}`.toLowerCase())
}

// 关闭抽屉
function onClose () {
  if (!isLoading.value) {
    // 重置
    RefForm.value.resetFields()
    dramaEqs.value = []
    visible.value = false
  }
}

// 添加集数
function touchAdd (eq) {
  const index = formState.eqs.indexOf(eq)
  if (index !== -1) {
    formState.eqs.splice(index, 1)
  } else {
    formState.eqs.push(eq)
  }
}

// 提交
function touchSubmit () {
  RefForm.value.validate().then(() => {
    isLoading.value = true
    const params = {
      ...formState
    }
    recommendAdd(params).then(res => {
      isLoading.value = false
      const { code, msg, data } = res
      if (code === 0) {
        message.success('操作成功')
        onClose()
        // 更新父组件
        emit('success')
      } else {
        message.error(res.message || msg)
      }
    })
  })
}

// 暴露出去
defineExpose({
  showDrawer
})

</script>

<style lang="less" scoped>
.a-link {
  margin-left: 8px;
}
.form-item-clear {
  margin-bottom: 0;
}
.form-upload-hint {
  padding-top: 6px;
  color: #8D8D8D;
}
.form-upload-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
}
.image-view.form-upload-item {
  margin-top: 10px;
  margin-left: 10px;
  position: relative;
  width: 118px;
  height: 148px;
  border-radius: 4px;
  overflow: hidden;
  img {
    display: inline-block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &.horizontal {
    width: 148px;
    height: 118px;
  }
}
.upload-item-select {
  position: absolute;
  right: 8px;
  bottom: 8px;
}
.upload-item-delete {
  position: absolute;
  right: 8px;
  top: 8px;
  font-size: 18px;
  color: #8D8D8D;
  cursor: pointer;
  opacity: 0;
}
.form-upload-item:hover .upload-item-delete {
  opacity: 1;
}
.date-range {
  color: #8D8D8D;
  padding-left: 100px;
  margin-top: -15px;
}
.eq-list {
  display: flex;
  flex-wrap: wrap;
  .eq-item {
    width: 50px;
    height: 50px;
    margin: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.footer-view {
  width: 100%;
  display: flex;
  align-items: center;
}
</style>