<template>
  <!-- 配置模板 -->
  <a-breadcrumb>
    <a-breadcrumb-item>内容管理</a-breadcrumb-item>
    <a-breadcrumb-item>推荐频道</a-breadcrumb-item>
  </a-breadcrumb>
  <div class="content">
    <div class="tool-view">
      <!-- 剧名 -->
      <span class="tool-title">剧名：</span>
      <a-input v-model:value="nameModel" style="width: 180px;" placeholder="请输入" />
      <!-- 操作 -->
      <a-button type="primary" style="margin-left: 16px;" @click="touchSearch">查询</a-button>
      <a-button style="margin-left: 16px;" @click="touchReset">重置</a-button>
      <div style="flex: 1;"></div>
      <!-- 其他操作 -->
      <a-button type="primary" @click="add"><plus-outlined />新增剧集</a-button>
    </div>
    <div class="table-box">
      <a-table
        :customRow="customRow"
        :data-source="dataSource"
        :columns="columns"
        :pagination="pagination"
        :row-key="record => record.id"
        :loading="isLoading"
        @change="handleTableChange"
      >
        <template #bodyCell="{ column, record }">
          <!-- 权重 -->
          <template v-if="column.key === 'weight'">
            <Edit
              :data="record"
              :editData="editData"
              @editStatus="editStatus"
              @editLoading="editLoading"
              @success="getData"
            ></Edit>
          </template>
          <!-- 操作 -->
          <template v-if="column.key === 'operation'">
            <a class="operation-item" @click="touchPreview(record)">预览</a>
            <a class="operation-item" @click="deleted(record)">删除</a>
          </template>
        </template>
      </a-table>
    </div>
    <!-- 新增、编辑弹窗 -->
    <Add ref="RefAdd" :source="source" @success="getData" />
    <!-- 预览 -->
    <PreviewVideoProModal ref="RefPreviewModal"></PreviewVideoProModal>
  </div>
</template>

<script setup>
import { ref, reactive, nextTick, onBeforeMount } from 'vue'
import { message, Modal } from 'ant-design-vue'
import { ClassifySettings } from '@/utils/constantList'
import Add from './components-rec/Add'
import Edit from './components-rec/Edit'
import PreviewVideoProModal from '@/components/preview/PreviewVideoProModal'
import { recommendList, recommendDelete } from '@/api/copyright'

// 视频预览
let RefPreviewModal = ref(null)
// 新增
let RefAdd = ref(null)
// 数据源
let dataSource = ref(null)
// 剧名
let nameModel = ref(undefined)
let name = ref(undefined)
// 加载
let isLoading = ref(false)
// 表头
let columns = reactive([
  {
    title: '权重',
    dataIndex: 'weight',
    key: 'weight',
    width: 120
  },
  // {
  //   title: '剧集ID',
  //   dataIndex: 'drama_id',
  //   key: 'drama_id'
  // },
  // {
  //   title: '顺序',
  //   dataIndex: 'id',
  //   key: 'id',
  // },
  {
    title: '短剧',
    dataIndex: 'remark_name',
    key: 'remark_name',
    width: 500
  },
  {
    title: '集数',
    dataIndex: 'eq_number',
    key: 'eq_number',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation'
  }
])
// 分页
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})
// 名称编辑中
let isEdit = ref(false)
// 编辑中的数据
let editData = ref({})

// 钩子函数 - onBeforeMount
onBeforeMount(() => {
  getData()
})

// 表格change
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  getData()
}

// 查询
function touchSearch () {
  // 调整参数
  name.value = nameModel.value
  pagination.current = 1
  // 获取列表
  getData()
}

// 重置
function touchReset () {
  nameModel.value = undefined
  name.value = undefined
  // 初始化获取
  getData()
}

// 请求分页数据
function getData () {
  isLoading.value = true
  const params = {
    name: name.value,
    page: pagination.current,
    page_size: pagination.pageSize
  }
  recommendList(params).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      dataSource.value = data.data
      pagination.total = data.total
    } else {
      message.error(res.message || msg)
    }
  })
}

// 新增弹窗
function add () {
  RefAdd.value.showDrawer()
}

// 预览
function touchPreview (record) {
  RefPreviewModal.value.showModal(record.drama_id, record.eq_number)
}

// 移除
function deleted (record) {
  Modal.confirm({
    title: '提示',
    content: '确认要进行删除操作吗？',
    cancelText: '取 消',
    okText: '确 定',
    onOk: () => {
      isLoading.value = true
      recommendDelete({ id: record.id }).then(res => {
        isLoading.value = false
        const { code, msg, data } = res
        if (code === 0) {
          message.success('删除成功')
          getData()
        } else {
          message.error(res.message || msg)
        }
      }).catch(() => {
        message.error('删除失败')
      })
    }
  })
}

// 列表自定义Row
function customRow (record) {
  return ({
    onMouseenter () {
      if (isEdit.value) { return }
      editData.value = record
    },
    onMouseleave () {
      if (isEdit.value) { return }
      editData.value = {}
    }
  })
}

// 编辑状态变更
// （注意：vue2版本子组件携带过来的直接就是ref的value，不需要通过.value赋值）
function editStatus (isedit) {
  isEdit.value = isedit
  if (!isEdit) {
    editData.value = {}
  }
}

// 编辑修改进度
// （注意：vue2版本子组件携带过来的直接就是ref的value，不需要通过.value赋值）
function editLoading (isLoading) {
  isLoading = isLoading
}

</script>

<style lang="less" scoped>
.content {
  padding: 24px 0;
  .tool-view {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  .operation-item {
    margin-right: 8px;
  }
}

</style>